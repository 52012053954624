import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { capitalizeWords } from "../allFunctions";

const Topbar = (props) => {
  const SideNavHidden = props.message;
  const lang = props.Language;
  const navigate = useNavigate();
  const LogoutClick = () => {
    localStorage.removeItem("LoggedIn", "false");
    localStorage.removeItem("email", "false");
    localStorage.removeItem("name", "false");
    localStorage.removeItem("defaultTag", "false");
    localStorage.removeItem("id", "false");
    localStorage.removeItem("tag  ", "false");
    navigate("/");
  };

  const [SelLang, setSelLang] = useState("en");
  const [show, setshow] = useState(true);

  let Name = localStorage.getItem("name");
  // let LoggedInName = "Admin";
  // if (text !== null) {
  //   let Val = text.split("@");
  //   LoggedInName = Val[ 0 ];
  // }

  useEffect(() => {
    const storedLang = localStorage.getItem("SelLang");
    if (storedLang === null) {
      setSelLang("en");
    } else {
      setSelLang(storedLang);
      props.onLangUpdate(storedLang);
    }
  }, []);

  const ChangeLang = (e) => {
    setSelLang(e.target.value);
    props.onLangUpdate(e.target.value);
    localStorage.setItem("SelLang", e.target.value);
  };

  const sideNavHandler = () => {
    let minWidth = window.innerWidth;
    let minheight = window.innerHeight;
    if ((minWidth <= 477) & (minheight <= 1150)) {
      props.onDataUpdate(show);
    } else {
      setshow(!show);
      props.onDataUpdate(show); // Call the callback function with data
    }
  };

  return (
    <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
      <button
        id="sidebarToggleTop"
        className="btn btn-link rounded-circle mr-3"
        onClick={sideNavHandler}
      >
        <i className="fa fa-bars"></i>
      </button>

      {/* <form className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search">
        <div className="input-group">
          <input
            type="text"
            className="form-control bg-light border-0 small"
            placeholder="Search for..."
            aria-label="Search"
            aria-describedby="basic-addon2"
          />
          <div className="input-group-append">
            <button className="btn btn-primary" type="button">
              <i className="fas fa-search fa-sm"></i>
            </button>
          </div>
        </div>
      </form> */}

      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown no-arrow d-sm-none">
          {/* <a
            className="nav-link dropdown-toggle"
            href="#"
            id="searchDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i className="fas fa-search fa-fw"></i>
          </a> */}
          <div
            className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
            aria-labelledby="searchDropdown"
          >
            <form className="form-inline mr-auto w-100 navbar-search">
              <div className="input-group">
                <input
                  type="text"
                  className="form-control bg-light border-0 small"
                  placeholder="Search for..."
                  aria-label="Search"
                  aria-describedby="basic-addon2"
                />
                <div className="input-group-append">
                  <button className="btn btn-primary" type="button">
                    <i className="fas fa-search fa-sm"></i>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </li>

        <div className="topbar-divider d-none d-sm-block"></div>
        <li
          className="nav-item dropdown no-arrow"
          style={{ marginRight: "3px" }}
        >
          <label className="fulfiledSelectLabel"></label>
          <select className="custom-select" value={lang} onChange={ChangeLang}>
            {/* <option value="" disabled >Language</option> */}
            <option value="en">English</option>
            <option value="ch">{lang === "en" ? "Chinese" : "中國人"}</option>
          </select>
        </li>
        <li className="nav-item dropdown no-arrow">
          <Dropdown>
            <Dropdown.Toggle className="bg-white border-white">
              <Link
                className="nav-link dropdown-toggle"
                href="#"
                id="userDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <img
                  className="img-profile rounded-circle"
                  src="/images/userimg.png"
                  alt="Avatar"
                />
              </Link>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item>
                <span className="mr-2 d-none d-lg-inline text-gray-600 small TopNavName">
                  {capitalizeWords(Name ?? "Groov Groov")}
                </span>
                <p onClick={LogoutClick}>
                  {" "}
                  <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-600" />{" "}
                  {lang === "en" ? "Logout" : "登出"}
                </p>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          <div
            className="dropdown-menu dropdown-menu-right shadow animated--grow-in"
            aria-labelledby="userDropdown"
          >
            <a className="dropdown-item" href="#">
              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
              Profile
            </a>
            <a className="dropdown-item" href="#">
              <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
              Settings
            </a>
            <a className="dropdown-item" href="#">
              <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
              Activity Log
            </a>
            <div className="dropdown-divider"></div>
            <a
              className="dropdown-item"
              href="#"
              data-toggle="modal"
              data-target="#logoutModal"
            >
              Logout
            </a>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Topbar;
