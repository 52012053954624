const capitalizeWords = (inputString) => {
  return inputString
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const StatusConversion = (data, lang) => {
  if (data === "manufactured") {
    return lang === "en" ? "Manufactured" : "已生产";
  } else if (data === "awaiting-review") {
    return lang === "en" ? "Awaiting-review" : "等待审核";
  } else if (data === "fulfilled") {
    return lang === "en" ? "Fulfilled" : "實現了";
  } else if (data === "reviewed") {
    return lang === "en" ? "Reviewed" : "已审核";
  } else if (data === "shipped") {
    return lang === "en" ? "Shipped" : "已发货";
  } else if (data === "quality-assured") {
    return lang === "en" ? "Quality Assured" : "质量已把关";
  } else if (data === "packaging") {
    return lang === "en" ? "Packaging" : "包装";
  } else if (data === "message") {
    return lang === "en" ? "Message" : "信息";
  } else if (data === "flagged") {
    return lang === "en" ? "Flagged Orders" : "已标记订单";
  }
};

const AllWordFirstChar = (inputString) => {
  return inputString
    ?.split(/\s+/)
    .map((word) => word[0])
    .join("");
};

const DateFormateMsg = (inputDateString) => {
  const date = new Date(inputDateString);
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};

function changeGMTFormate(str) {
  if (typeof str === "string" && str.trim() !== "") {
    const inputDateString = `${str.replace(" ", "T")}.000Z`;

    const inputDate = new Date(inputDateString);

    if (isNaN(inputDate)) {
      return "Invalid date format";
    }

    const gmtTime = new Date(
      inputDate.getTime() - inputDate.getTimezoneOffset() * 60 * 1000
    );

    if (isNaN(gmtTime)) {
      return "Failed to convert to GMT/UTC time";
    }

    const formattedGmtTime = gmtTime
      .toISOString()
      .replace("T", " ")
      .slice(0, 19);
    // console.log(formattedGmtTime)
    return formattedGmtTime;
  } else {
    return new Date();
  }
}

function formatTimestamp(timestamp) {
  // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const now = new Date();
  const messageTime = new Date(timestamp);

  // Calculate the time difference in milliseconds
  const timeDifference = now - messageTime;

  // Check if the message was sent "just now"
  if (timeDifference < 60000) {
    return "Just now";
  }

  // Check if the message was sent less than an hour ago
  if (timeDifference < 3600000) {
    const minutesAgo = Math.floor(timeDifference / 60000);
    return `${minutesAgo} minute${minutesAgo !== 1 ? "s" : ""} ago`;
  }

  // Check if the message was sent today
  if (
    now.getDate() === messageTime.getDate() &&
    now.getMonth() === messageTime.getMonth() &&
    now.getFullYear() === messageTime.getFullYear()
  ) {
    const options = {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      hourCycle: "h12",
    };
    return messageTime.toLocaleTimeString("en-US", options);
  }

  // Check if the message was sent within the current year
  if (now.getFullYear() === messageTime.getFullYear()) {
    const options = {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      hourCycle: "h12",
    };
    return messageTime.toLocaleDateString("en-US", options);
  }

  // If none of the above conditions match, it's in a different year
  const optionsWithYear = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    hourCycle: "h12",
  };
  return messageTime.toLocaleDateString("en-US", optionsWithYear);
}

export {
  capitalizeWords,
  AllWordFirstChar,
  DateFormateMsg,
  formatTimestamp,
  StatusConversion,
  changeGMTFormate,
};
