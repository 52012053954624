import React from 'react'
import { Navigate } from 'react-router-dom'
function Protected({ children }) {
  const isSignedIn = localStorage.getItem("LoggedIn");

  if (!isSignedIn) {
    return <Navigate to="/" replace />
  } else {
    // if(children.type.name === "Login"){
    //   return <Navigate to="/layout" replace />
    // }
    return children
  }
}
export default Protected