import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  CBadge,
  CButton,
  CForm,
  CFormTextarea,
  CLoadingButton,
} from "@coreui/react-pro";
import toast, { Toaster } from "react-hot-toast";
import OrderSingle from "../Components/OrderSingle";
import {
  AllWordFirstChar,
  capitalizeWords,
  changeGMTFormate,
  formatTimestamp,
} from "../Components/allFunctions";
import { CircleLoader } from "react-spinners";

const OrderDetailsPage = (props) => {
  const lang = props.Language;
  const navigate = useNavigate();
  const CMS_ENDPOINT =
    process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_CMS_ROUTE;

  const params = useParams();
  const [tagLoading, setTagLoading] = useState(false);
  const [OrderFound, setOrderFound] = useState(true);
  const [Orderjson, setOrderJson] = useState([]);
  const [OrderDBData, setOrderDBData] = useState([]);

  const [fulfilled, setFulfilled] = useState("");
  const [OrderDate, setOrderDate] = useState("");
  const [note, setNote] = useState([]);
  const [OrderMessage, setOrderMessage] = useState({
    // flagVisible: true,
    msgValue: "",
    message: [],
    msgLoading: false,
  });

  const [Flag, setFlag] = useState(false);
  const [OrderData, setOrderData] = useState(false);

  // console.log(OrderMessage.message)
  const HandleMessage = () => {
    if (OrderMessage.msgValue !== "") {
      setOrderMessage({ ...OrderMessage, msgLoading: true });

      const UserId = localStorage.getItem("id"),
        UserEmail = localStorage.getItem("email"),
        UserName = localStorage.getItem("name");

      const msgData = {
        UserId,
        UserEmail,
        UserName,
        MessageTime: Date.now(),
        Message: OrderMessage.msgValue,
      };

      fetch(`${CMS_ENDPOINT}/${params?.id}/message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(msgData),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === true) {
            toast.success(lang === "en" ? res.message : "成功");
            const newMessageArray = [...OrderMessage.message, msgData];
            setOrderMessage((prevOrderMessage) => ({
              ...prevOrderMessage,
              message: newMessageArray,
              msgValue: "",
              msgLoading: false,
            }));
          } else {
            toast.error(res.message);
            console.log("Line 73" + res.message);
            setOrderMessage({ ...OrderMessage, msgLoading: false });
          }
        })
        .catch((error) => {
          setOrderMessage({ ...OrderMessage, msgLoading: false });
          toast.error(error.message);
          console.log("Line 80" + error.message);
        });
    } else {
      toast.error(lang === "en" ? "Please fill details" : "请填写详细信息");
    }
  };

  const getMessage = () => {
    const orderId = params?.id;
    fetch(`${CMS_ENDPOINT}/${orderId}/message`)
      .then((res) => res.json())
      .then((res) => {
        setOrderMessage((prsVal) => ({ ...prsVal, message: res.data }));
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const getFlag = (e) => {
    fetch(`${CMS_ENDPOINT}/flag/${params?.id}`)
      .then((response) => response.json())
      .then((data) => {
        setOrderData(data.data);

        setFlag(data.data.Flag);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
        console.log("Line 108" + error.message);
      });
  };

  const SingleOrder = () => {
    fetch(`${CMS_ENDPOINT}/SingleOrder`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: params?.id }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log(data)
        if (data.status === true) {
          setOrderFound(true);
          setOrderJson([data.data]);
          setOrderDBData(data.dbOrderData);

          console.log("Order Details Data", data.data);

          setFulfilled(
            data.data.fulfillment_status == null
              ? "Unfulfilled"
              : capitalizeWords(data?.data?.fulfillment_status ?? "")
          );
          const date = new Date(data.data.created_at);
          setOrderDate(date.toLocaleString());
          setNote(data.data.note);
        } else {
          setOrderFound(false);
        }
      })
      .catch((error) => {
        toast.error(error.message);
        console.log("Line 140" + error.message);
      });
  };

  useEffect(() => {
    SingleOrder();
    getMessage();
    getFlag();
  }, []);

  const HandleUpdate = () => {
    setTagLoading(true);
    try {
      fetch(`${CMS_ENDPOINT}/AddOrderNotes`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: params?.id,
          note: note,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.status) {
            setTagLoading(false);
            toast.success(lang === "en" ? "Updated successfully" : "更新成功");
          } else {
            setTagLoading(false);
            setNote("");
          }
        });
    } catch (error) {
      setTagLoading(false);
      console.log(`Error: ${error}`);
    }
  };

  const handleFlag = (e) => {
    try {
      setFlag(e);
      const userId = localStorage.getItem("id");
      fetch(`${CMS_ENDPOINT}/flag/${params?.id}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Flag: e === true ? 1 : 0, FlagBy: userId }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log(data);
          toast.success(lang === "en" ? data.message : "成功");
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.message);
          console.log("Line 201" + error.message);
        });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      console.log("Line 207" + error.message);
    }
  };

  async function MessageCheck(e, index, msgId) {
    try {
      let newArr = [...OrderMessage.message];
      newArr[index]["MarkAsRead"] = e.target.checked ? 1 : 0;
      setOrderMessage((presVal) => ({ ...presVal, newArr }));
      // console.log(newArr, msgId);

      fetch(`${CMS_ENDPOINT}/${params?.id}/message/${msgId}`, {
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ MarkAsRead: e.target.checked ? 1 : 0 }),
      })
        .then((response) => response.json())
        .then((data) => {
          toast.success(data.message);
          console.log("Line 230" + data.message);
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.message);
          console.log("Line 236" + error.message);
        });
    } catch (error) {
      console.log(error);
      toast.error(error.message);
      console.log("Line 242" + error.message);
    }
  }

  return OrderFound ? (
    Orderjson.length ? (
      <div className="container-fluid">
        {/* Page Heading */}
        <div className="order-details-head d-flex justify-content-between">
          <div>
            {/* <h2 className="h3 mb-2 text-gray-800">Orders</h2> */}
            <p className="mt-3">
              <span className="BackButton" onClick={() => navigate(-1)}>
                <i className="fas fa-arrow-left backbtn"></i>
              </span>
              <span className="h2 mb-2 text-secondary ">
                {" "}
                {Orderjson[0].name}{" "}
              </span>
              <CBadge color="dark text-white" className="CBadge">
                {capitalizeWords(Orderjson[0]?.tags ?? "")}
              </CBadge>{" "}
              <CBadge color="info text-white" className="CBadge">
                {fulfilled}
              </CBadge>
            </p>
            <p className="mb-2 text-secondary">{OrderDate} </p>
          </div>
        </div>

        <div className="order-detail-body">
          <div key={`singleproduct`}>
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mb-4"></div>
              </div>
              <div className="col-md-4">
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="order-details-title">
                          {lang === "en" ? "Customer Info" : "客户信息"}
                        </h5>
                        <div>
                          <ul className="enterprisedetails">
                            <li>
                              {Orderjson[0]?.customer?.first_name +
                                " " +
                                Orderjson[0]?.customer?.last_name}
                            </li>
                            <li>{Orderjson[0]?.customer?.email}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="order-details-title">
                          {lang === "en" ? "Enterprise Info" : "客户信息"}
                        </h5>
                        <div>
                          <ul className="enterprisedetails">
                            <li>
                              <span>Enterprise Name</span>:{" "}
                              {OrderData?.EnterpriseName}
                            </li>
                            <li>
                              <span>Trainer Name</span>:{" "}
                              {OrderDBData?.[0]?.TrainerName}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <h5 className="order-details-title">
                          {lang === "en" ? "Shipping Address" : "邮寄地址"}
                        </h5>
                        <div>
                          <p>
                            {Orderjson[0]?.shipping_address?.first_name}{" "}
                            {Orderjson[0]?.shipping_address?.last_name}
                          </p>
                          <p>
                            {Orderjson[0]?.shipping_address?.address1}
                            {Orderjson[0]?.shipping_address?.address2}{" "}
                          </p>
                          <p>
                            {Orderjson[0]?.shipping_address?.city}{" "}
                            {Orderjson[0]?.shipping_address?.province_code}{" "}
                            {Orderjson[0]?.shipping_address?.zip}{" "}
                            {Orderjson[0]?.shipping_address?.country}{" "}
                          </p>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {Orderjson[0]?.line_items?.map((itemVal, i) => {
              return (
                <OrderSingle
                  key={i}
                  Orderjson={Orderjson}
                  itemVal={itemVal}
                  i={i}
                  fulfilled={fulfilled}
                  lang={lang}
                  OrderDBData={OrderDBData}
                />
              );
            })}

            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={Flag}
                        id="flexCheckDefaultflag"
                        onChange={(e) => handleFlag(e.target.checked)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexCheckDefaultflag"
                      >
                        {lang === "en" ? "Flag it!" : "标记 !"}
                      </label>
                      {/* <CForm>
                    <CFormTextarea
                      id="exampleFormControlTextarea1"
                      label="Note"
                      rows={3}
                      value={note}
                      onChange={(e) => {
                        setNote(e.target.value);
                      }}
                    ></CFormTextarea>
                    <br />
                    {tagLoading ? <CLoadingButton color="dark">Update</CLoadingButton> :
                      <CButton color="dark" onClick={HandleUpdate}>
                        Update
                      </CButton>
                    }
                  </CForm> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <ul className="list-groupuu">
                      {OrderMessage?.message?.map((msgVal, i) => {
                        return (
                          // <li className="list-group-item" key={i}>
                          //   <input
                          //     className="form-check-input"
                          //     type="checkbox"
                          //     checked={msgVal.MarkAsRead}
                          //     id="flexCheckDefaultflag"
                          //     onChange={(e) => MessageCheck(e, i, msgVal.ID)}
                          //   // onChange={(e) =>
                          //   //   setOrderMessage((presVal) => ({ ...presVal }))
                          //   // }
                          //   />
                          //   {msgVal.Message}
                          // </li>

                          <li className="list-group-item" key={i}>
                            <div className="grv-comment-ref">
                              <span className="grv-comment-by">
                                {msgVal.UserName}
                              </span>
                              <span className="grv-comment-time">
                                {formatTimestamp(
                                  changeGMTFormate(msgVal?.MessageTime)
                                )}
                              </span>
                            </div>
                            <div className="grv-comment-data">
                              <span className="grv-comment-circle text-light">
                                {AllWordFirstChar(msgVal?.UserName) ?? "GA"}
                              </span>
                              <p className="grv-comment-comment">
                                {msgVal.Message}
                              </p>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    <CForm>
                      <CFormTextarea
                        id="exampleFormControlTextarea1"
                        label={lang === "en" ? "Message" : "信息"}
                        rows={3}
                        value={OrderMessage.msgValue}
                        onChange={(e) =>
                          setOrderMessage((presVal) => ({
                            ...presVal,
                            msgValue: e.target.value,
                          }))
                        }
                      ></CFormTextarea>
                      <br />
                      {OrderMessage.msgLoading ? (
                        <CLoadingButton color="dark" type="button">
                          {lang === "en" ? "Add Message" : "添加留言"}
                        </CLoadingButton>
                      ) : (
                        <CButton color="dark" onClick={HandleMessage}>
                          {lang === "en" ? "Add Message" : "添加留言"}
                        </CButton>
                      )}
                    </CForm>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card shadow mb-4">
                  <div className="card-body">
                    <CForm>
                      <CFormTextarea
                        id="exampleFormControlTextarea1"
                        label={lang === "en" ? "Note" : "笔记"}
                        rows={3}
                        value={note}
                        onChange={(e) => {
                          setNote(e.target.value);
                        }}
                      ></CFormTextarea>
                      <br />
                      {tagLoading ? (
                        <CLoadingButton color="dark">
                          {lang === "en" ? "Add Note" : "添加註釋"}
                        </CLoadingButton>
                      ) : (
                        <CButton color="dark" onClick={HandleUpdate}>
                          {lang === "en" ? "Add Note" : "添加註釋"}
                        </CButton>
                      )}
                    </CForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    ) : (
      <>
        <div style={{ height: "100%" }} role="status">
          <CircleLoader className="LoaderSingleOrder" color="#1ca1ee" />
        </div>
      </>
    )
  ) : (
    <div>
      <h1 className="text-dark mx-auto">
        {lang === "en" ? "Order not found" : "未找到訂單"}
      </h1>
    </div>
  );
};

export default OrderDetailsPage;
