import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { GoogleLogin, useGoogleLogin, googleLogout } from "@react-oauth/google";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";

const CMS_ENDPOINT =
  process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_CMS_ROUTE;

const Login = () => {
  const [email, setEmail] = useState();
  const [pswHideShow, setPswHideShow] = useState(false);
  const [tags, setTags] = useState();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };

  const [user, setUser] = useState([]);
  const [profile, setProfile] = useState([]);

  const socialLogin = useGoogleLogin({
    onSuccess: (codeResponse) => {
      console.log("success", codeResponse);
      setUser(codeResponse);
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  useEffect(() => {
    if (user) {
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: "application/json",
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          console.log("profiledata", res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [user]);

  // log out function to log the user out of google and set the profile array to null
  const logOut = () => {
    googleLogout();
    setProfile(null);
  };

  const LoginUserData = [
    {
      email: "admin@groov.com",
      password: "123456",
    },
    {
      email: "vendor1@groov.com",
      password: "123456",
    },
    {
      email: "vendor2@groov.com",
      password: "123456",
    },
  ];

  const loginSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    fetch(`${CMS_ENDPOINT}/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: formValues.email,
        password: formValues.password,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          toast.success(res.message);
          const { id, name, tag, defaultTag, lastLogin } = res.data;

          const expiration = new Date();
          expiration.setTime(expiration.getTime() + 1 * 60 * 60 * 1000);
          const expires = "expires=" + expiration.toUTCString();
          document.cookie = "loginCookies=true;" + expires + ";path=/";

          setTimeout(() => {
            localStorage.setItem("LoggedIn", "true");
            localStorage.setItem("tag", tag);
            localStorage.setItem("id", id);
            localStorage.setItem("defaultTag", defaultTag == 1 ? true : false);
            localStorage.setItem("email", formValues.email);
            localStorage.setItem("name", name);
            localStorage.setItem("lastLogin", lastLogin);
            localStorage.setItem("CurrentLogin", new Date());
            setEmail(formValues.email);
            navigate("/dashboard");
          }, 1000);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(res.message);
          navigate("/");
        }
      })
      .catch((error) => {
        toast.error(error.message);
        setLoading(false);
      });
  };

  const googleRegisterSuccess = (googleResponse) => {
    console.log("googleResponse", googleResponse);
    fetch("http://localhost:3003/api/auth/signup", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id_token: googleResponse?.credential }),
    })
      .then((res) => {
        if (!res.ok) throw res;

        alert("Done");
        // router.push({
        //   pathname: "/login",
        // });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const googleRegisterFailure = (googleResponse) => {
    console.error(googleResponse);
  };

  return (
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="rowLoginPage">
                <div className="col-lg-6 d-none d-lg-block bg-login-image"></div>
                <div className="col-lg-6 login-form">
                  <div className="p-5">
                    <div className="text-center">
                      <img
                        className="LoginLogoResponsive"
                        src="images/logo.png"
                        alt=""
                      />
                      <h1 className="h4 text-gray-900 mb-4 fw-bold">Login</h1>
                    </div>
                    <form className="user" onSubmit={loginSubmit}>
                      <div className="form-group">
                        <input
                          type="email"
                          name="email"
                          value={formValues.email}
                          onChange={handleChange}
                          className="form-control form-control-user"
                          id="exampleInputEmail"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email Address..."
                        />
                      </div>
                      <div className="form-group position-relative">
                        <input
                          type={pswHideShow ? "text" : "password"}
                          name="password"
                          value={formValues.password}
                          onChange={handleChange}
                          className="form-control form-control-user"
                          id="exampleInputPassword"
                          placeholder="Password"
                        />
                        <i
                          className={`fa ${
                            pswHideShow ? "fa-eye-slash" : "fa-eye"
                          } showpswlogin-eye`}
                          aria-hidden="true"
                          onClick={() => setPswHideShow(!pswHideShow)}
                        ></i>
                      </div>
                      <div className="form-group">
                        <div className="custom-control custom-checkbox small">
                          {/* <input
                            type="checkbox"
                            className="custom-control-input"
                            id="customCheck"
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="customCheck"
                          >
                            Remember Me
                          </label> */}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary btn-user btn-block"
                      >
                        {loading ? (
                          <span
                            className="spinner-border spinner-border-sm text-Light"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          <span>Login</span>
                        )}
                      </button>
                    </form>
                    <div>
                      {/* <GoogleLogin
                        clientId={
                          "400514316835-22j7iuba1db11vs6un3047nhvif54khg.apps.googleusercontent.com"
                        }
                        responseType={"id_token"}
                        buttonText="Register with Google"
                        onSuccess={googleRegisterSuccess}
                        onFailure={googleRegisterFailure}
                      />
                      <div className="social-login-wrap">
                        <h3>Login With</h3>
                        {profile ? (
                          <div>
                            <img src={profile.picture} alt="user image" />
                            <h3>User Logged in</h3>
                            <p>Name: {profile.name}</p>
                            <p>Email Address: {profile.email}</p>

                            <button
                              className="btn-primary btn"
                              onClick={logOut}
                            >
                              Log out
                            </button>
                          </div>
                        ) : (
                          <button
                            className="social-btn google"
                            onClick={() => socialLogin()}
                          >
                            Continue with Google
                          </button>
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </div>
  );
};

export default Login;
