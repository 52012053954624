import React from 'react';
import { Navigate } from 'react-router-dom';

function LoginProtected({ children }) {
  const isSignedIn = localStorage.getItem('LoggedIn');

  return isSignedIn ? (
    <Navigate to="/dashboard" replace />
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
}

export default LoginProtected;
