import { CSmartTable } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  capitalizeWords,
  changeGMTFormate,
  StatusConversion,
} from "../../Components/allFunctions";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const CMS_ENDPOINT =
  process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_CMS_ROUTE;

const OrdersPage = (props) => {
  const lang = props.Language;
  // const [fullfillesData, setFullfilledData] = useState([]);
  // const [unFullfillesData, setUnFullfilledData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  const [accessToken, setAccessToken] = useState("");
  const [OrderStatus, setOrderStatus] = useState("");

  const navigate = useNavigate();
  const changeHandler = (e) => {
    setOrderStatus(e.target.value);
    navigate(e.target.value);
  };
  const customItemsPerPageLabel =
    lang === "en" ? "Items per Page:" : "项目/页 : ";
  const customTableFilterLabel = lang === "en" ? "Filter" : "筛选";

  const columns = [
    {
      key: "id",
      label: "ID",
      filter: false,
      sorter: false,
    },
    {
      key: "OrderName",

      label: lang === "en" ? "Order" : "订单",
    },
    {
      key: "CreatedAt",
      label: lang === "en" ? "Date" : "日期",
    },
    {
      key: "CustomerName",
      label: lang === "en" ? "Customer" : "客户",
    },
    {
      key: "FulfillmentStatus",
      label: lang === "en" ? "Fulfillment Status" : "进度状态",
    },
    {
      key: "Items",
      label: lang === "en" ? "Items" : "项目",
    },
    {
      key: "Tag",
      label: lang === "en" ? "Vendors" : "供应商",
    },
    {
      key: "Flag",
      label: lang === "en" ? "Flagged" : "标记",
    },
    {
      key: "FlaggedBy",
      label: lang === "en" ? "Flagged By" : "以xx标记",
    },
    {
      key: "MessageCount",
      label: lang === "en" ? "Message Count" : "消息數",
    },
  ];

  const getAllOrders = () => {
    try {
      setLoading(true);
      let email = localStorage.getItem("email");
      let id = localStorage.getItem("id");

      if ((email !== null) & (id !== null)) {
        fetch(`${CMS_ENDPOINT}/orders?email=${email}&id=${id}`)
          .then((response) => response.json())
          .then((orders) => {
            // console.log(orders.orders);
            setOrders(orders.orders);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setLoading(false);
        console.log("Please Login First");
      }
    } catch (error) {}
  };

  const fulfillment_status = orders.map((order) => {
    return order.fulfillment_status;
  });

  useEffect(() => {
    getAllOrders();
  }, []);

  console.log("Order Page");

  return (
    <div className="container-fluid">
      {/* <div className="ForAndBak">
      <div className="BackButton" onClick={() => navigate(-1)}><i className="fas fa-arrow-left backbtn"></i></div>
        </div> */}

      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800 HeadTitles">
          {lang === "en" ? "Orders" : "订单"}
        </h1>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 mb-12">
          <div className="card shadow mb-4 flaged">
            <div className="card-body orders-table">
              <div className="tableDropDown">
                <div className="DropdownTittle">
                  {lang === "en" ? "Order Status" : "訂單狀態"}
                </div>
                <select
                  className="custom-select"
                  value={OrderStatus}
                  onChange={changeHandler}
                >
                  <option value="" disabled>
                    {lang === "en" ? "Order Status" : "訂單狀態"}
                  </option>
                  <option value="/orders">
                    {lang === "en" ? "All Orders" : "所有訂單"}
                  </option>
                  <option value="/orders/status/awaiting-review">
                    {lang === "en" ? "Awaiting Review" : "等待审核"}
                  </option>
                  <option value="/orders/status/reviewed">
                    {lang === "en" ? "Reviewed" : "已审核"}
                  </option>
                  <option value="/orders/status/manufactured">
                    {lang === "en" ? "Manufactured" : "已生产"}
                  </option>
                  <option value="/orders/status/quality-assured">
                    {lang === "en" ? "Quality Assured" : "质量已把关"}
                  </option>
                  <option value="/orders/status/packaging">
                    {lang === "en" ? "Packaging" : "包装"}
                  </option>
                  <option value="/orders/status/shipped">
                    {lang === "en" ? "Shipped" : "已发货"}
                  </option>
                  <option value="/orders/status/fulfilled">
                    {lang === "en" ? "Fulfilled" : "實現了"}
                  </option>
                </select>
              </div>

              <div className="custom-table">
                {/* <label htmlFor="tableFilter">{customTableFilterLabel}</label> */}
                <CSmartTable
                  responsive="true"
                  items={orders}
                  columns={columns}
                  columnSorter
                  tableFilter
                  tableFilterPlaceholder={
                    lang === "en" ? "Search..." : "搜索..."
                  }
                  tableFilterLabel={customTableFilterLabel}
                  pagination
                  loading={loading}
                  itemsPerPage={20}
                  itemsPerPageSelect
                  itemsPerPageLabel={customItemsPerPageLabel}
                  tableProps={{
                    hover: true,
                    responsive: true,
                    striped: true,
                    className: "flagged-table",
                  }}
                  scopedColumns={{
                    id: (item, i) => {
                      const flaggedClassName =
                        // item.Flag === 1 ? "flagged-row" : "";
                        item.Flag === 1 ? "" : "";
                      return <td className={flaggedClassName}>{i + 1}.</td>;
                    },

                    OrderName: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";

                      return (
                        <td className={flaggedClassName}>
                          <Link
                            to={`/order/${items.OrderId}`}
                            className={flaggedClassName}
                          >
                            {items.OrderName}
                          </Link>
                        </td>
                      );
                    },
                    CreatedAt: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {changeGMTFormate(items.CreatedAt)}
                        </td>
                      );
                    },

                    Flag: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {items.Flag === 1
                            ? lang === "en"
                              ? "Flag"
                              : "旗幟"
                            : ""}
                        </td>
                      );
                    },

                    FlaggedBy: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {items.FlagBy === "null" || items.Flag === 0
                            ? ""
                            : capitalizeWords(items.FlagBy)}
                        </td>
                      );
                    },
                    MessageCount: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {items.messageCount != "0" ? (
                            <div className="OrderMessageCount">
                              {items.messageCount}
                            </div>
                          ) : lang === "en" ? (
                            "No Message"
                          ) : (
                            "沒有留言"
                          )}
                        </td>
                      );
                    },

                    FulfillmentStatus: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {/* {capitalizeWords(items.FulfillmentStatus)} */}
                          {StatusConversion(items.FulfillmentStatus, lang)}
                        </td>
                      );
                    },

                    CustomerName: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {capitalizeWords(items.CustomerName)}
                        </td>
                      );
                    },
                    Items: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {capitalizeWords(items.Items)}
                        </td>
                      );
                    },
                    Tag: (items) => {
                      const flaggedClassName =
                        // items.Flag === 1 ? "flagged-row" : "";
                        items.Flag === 1 ? "" : "";
                      return (
                        <td className={flaggedClassName}>
                          {capitalizeWords(items.Tag)}
                        </td>
                      );
                    },
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersPage;
