import { useState } from "react";
import { Link } from "react-router-dom";
import Topbar from "../Topbar/Topbar";

const Sidebar = (props) => {
  const [show, setshow] = useState(false);
  const [sideBar, setsideBar] = useState(false);
  const [SidebarIn, SetSidebarIn] = useState(false);
  const [path, setPath] = useState("");
  const ToggleHandler = () => {
    setshow(!show);
  };

  const SideNavHidden = props.message;
  const lang = props.Language;

  const sideNavHandler = () => {
    props.onDataUpdate(SidebarIn); // Call the callback function with data
  };

  return (
    <>
      <div
        className={`${
          sideBar || SideNavHidden
            ? "SideBarToggle "
            : "SideBarToggleResponsive"
        } `}
      >
        <ul
          className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion"
          id="accordionSidebar"
        >
          <div className="sidebar-logo">
            <Link
              to="/"
              className="sidebar-brand d-flex align-items-center justify-content-center"
              href="index.html"
            >
              <img src="/images/logo.png" alt="..." />
            </Link>
          </div>
          <hr className="sidebar-divider my-0" />

          <li onClick={sideNavHandler} className="nav-item active">
            <Link className="nav-link" to="/dashboard">
              <i
                className={`${
                  window.location.pathname === "/dashboard"
                    ? "fas fa-fw fa-tachometer-alt ActiveColor mr-3"
                    : "fas fa-fw fa-tachometer-alt mr-3"
                }`}
              ></i>
              <span
                className={`${
                  window.location.pathname === "/dashboard"
                    ? "ActiveColor"
                    : "collapse-item"
                }`}
              >
                {lang === "en" ? "Dashboard" : "仪表盘"}
              </span>
            </Link>
          </li>

          {/* <hr className="sidebar-divider" /> */}

          <div className="sidebar-heading">
            {lang === "en" ? "Interface" : "界面"}
          </div>

          {/* <li className="nav-item">
          <Link
            className="nav-link collapsed"
            to="/orders"
            data-toggle="collapse"
            data-target="#collapseTwo"
            aria-expanded="true"
            aria-controls="collapseTwo"
          >
            <i className="fas fa-fw fa-table"></i>
            <span>Orders</span>
          </Link>
          <div
            id="collapseTwo"
            className="collapse"
            aria-labelledby="headingTwo"
            data-parent="#accordionSidebar"
          >
            <div className="bg-white py-2 collapse-inner rounded">
              <h6 className="collapse-header">Custom Components:</h6>
              <a className="collapse-item" href="buttons.html">
                Buttons
              </a>
              <a className="collapse-item" href="cards.html">
                Cards
              </a>
            </div>
          </div> 

           <div className="dropdown">
            <button
              className="btn btn-secondary dropdown-toggle bg-transparent Ordertoggle ml-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            ><i className="fas fa-fw fa-table"></i> 
              <span className="pl-1">Orders</span>
            </button>


            <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item" to="/orders">
                  All Orders
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/fulfiled-orders">
                  Fulfilled Orders
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/unfulfiled-orders">
                  Open Orders
                </Link>
              </li>
            </ul>
          </div>
 



        </li> */}

          <li className="nav-item">
            <div
              onClick={ToggleHandler}
              className={`${
                show
                  ? "nav-link SideBarDropdown"
                  : "nav-link collapsed SideBarDropdown "
              } `}
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              {/* <span className="OrdersToggle"> */}{" "}
              {/* <i className="fas fa-fw fa-cog"></i> Orders */}
              <i className="fas fa-shopping-cart OrdersToggle mr-3"> </i>
              <span className="OrdersToggle">
                {lang === "en" ? "Orders" : "订单"}
              </span>
              {/* </span> */}
            </div>
            <div
              id="collapseTwo"
              // className="collapse"
              className={`${show ? "collapse show" : "collapse"}`}
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className=" DropDownInner py-2 collapse-inner rounded">
                {/* <Link className="collapse-item" onClick={handlePath} to="/orders"> */}
                <Link
                  onClick={() => {
                    setPath(window.location.pathname);
                    sideNavHandler();
                  }}
                  className={`${
                    window.location.pathname === "/orders"
                      ? "ActiveColor collapse-item"
                      : "collapse-item"
                  }`}
                  to="/orders"
                >
                  {lang === "en" ? "All Orders" : "所有訂單"}
                </Link>

                <Link
                  onClick={sideNavHandler}
                  className={`${
                    window.location.pathname === "/orders/awaiting-review"
                      ? "ActiveColor collapse-item"
                      : "collapse-item"
                  }`}
                  to="/orders/awaiting-review"
                >
                  {lang === "en" ? "Awaiting Review" : "等待审核"}
                </Link>
                <Link
                  onClick={sideNavHandler}
                  className={`${
                    window.location.pathname === "/orders/reviewed"
                      ? "ActiveColor collapse-item"
                      : "collapse-item"
                  }`}
                  to="/orders/reviewed"
                >
                  {lang === "en" ? "Reviewed" : "已审核"}
                </Link>
                <Link
                  onClick={sideNavHandler}
                  className={`${
                    window.location.pathname === "/orders/manufactured"
                      ? "ActiveColor collapse-item"
                      : "collapse-item"
                  }`}
                  to="/orders/manufactured"
                >
                  {lang === "en" ? "Manufactured" : "已生产"}
                </Link>
                <Link
                  onClick={sideNavHandler}
                  className={`${
                    window.location.pathname === "/orders/quality-assured"
                      ? "ActiveColor collapse-item"
                      : "collapse-item"
                  }`}
                  to="/orders/quality-assured"
                >
                  {lang === "en" ? "Quality Assured" : "质量已把关"}
                </Link>
                <Link
                  onClick={sideNavHandler}
                  className={`${
                    window.location.pathname === "/orders/packaging"
                      ? "ActiveColor collapse-item"
                      : "collapse-item"
                  }`}
                  to="/orders/packaging"
                >
                  {lang === "en" ? "Packaging" : "包装"}
                </Link>
                <Link
                  onClick={sideNavHandler}
                  className={`${
                    window.location.pathname === "/orders/shipped"
                      ? "ActiveColor collapse-item"
                      : "collapse-item"
                  }`}
                  to="/orders/shipped"
                >
                  <p>{lang === "en" ? "Shipped" : "已发货"}</p>
                </Link>
                <Link
                  onClick={sideNavHandler}
                  className={`${
                    window.location.pathname === "/orders/fulfilled"
                      ? "ActiveColor collapse-item"
                      : "collapse-item"
                  }`}
                  to="/orders/fulfilled"
                >
                  {lang === "en" ? "Fulfilled" : "實現了"}
                </Link>
              </div>
            </div>

            {localStorage?.getItem("tag") === "admin" ? (
              <Link
                onClick={sideNavHandler}
                className={`${
                  window.location.pathname === "/vendor"
                    ? "ActiveColor nav-link collapsed"
                    : "nav-link collapsed"
                }`}
                to="/vendor"
                // data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <i
                  className={`${
                    window.location.pathname === "/vendor"
                      ? "fas fa-fw fa-user ActiveColor mr-3"
                      : "fas fa-fw fa-user mr-3"
                  }`}
                ></i>
                <span>{lang === "en" ? "Vendors" : "供货商"}</span>
              </Link>
            ) : (
              ""
            )}

            {/* {localStorage?.getItem("tag") === "admin" ? (
              <Link
                onClick={sideNavHandler}
                className={`${
                  window.location.pathname === "/enterprise"
                    ? "ActiveColor nav-link collapsed"
                    : "nav-link collapsed"
                }`}
                to="/enterprise"
                // data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                <i
                  className={`${
                    window.location.pathname === "/enterprise"
                      ? "fas fa-fw fa-user ActiveColor mr-3"
                      : "fas fa-fw fa-user mr-3"
                  }`}
                ></i>
                <span>{lang === "en" ? "Enterprise" : "企业"}</span>
              </Link>
            ) : (
              ""
            )} */}

            {/* {localStorage?.getItem("tag") === "admin" ? ( */}
            <Link
              onClick={sideNavHandler}
              className={`${
                window.location.pathname === "/orders/message"
                  ? "ActiveColor nav-link collapsed"
                  : "nav-link collapsed"
              }`}
              to="/orders/message"
              // data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              {/* <i className="fas fa-comments"></i> */}
              <i
                className={`${
                  window.location.pathname === "/orders/message"
                    ? "fas fa-comments ActiveColor mr-3"
                    : "fas fa-comments mr-3"
                }`}
              ></i>
              <span>{lang === "en" ? "Messages" : "信息"}</span>
            </Link>
            {/* ) : (
            ""
          )} */}

            {/* {localStorage?.getItem("tag") === "admin" ? ( */}
            <Link
              onClick={sideNavHandler}
              className={`${
                window.location.pathname === "/orders/flagged"
                  ? "ActiveColor nav-link collapsed"
                  : "nav-link collapsed"
              }`}
              to="/orders/flagged"
              // data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <i
                className={`${
                  window.location.pathname === "/orders/flagged"
                    ? "fas fa-flag ActiveColor mr-3"
                    : "fas fa-flag mr-3"
                }`}
              ></i>
              <span>{lang === "en" ? "Flagged Orders" : "已标记订单"}</span>
            </Link>
            {/* ) : (
            ""
          )} */}

            <Link
              onClick={sideNavHandler}
              className={`${
                window.location.pathname === "/profile"
                  ? "ActiveColor nav-link collapsed"
                  : "nav-link collapsed"
              }`}
              to="/profile"
              // data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="true"
              aria-controls="collapseTwo"
            >
              <i
                className={`${
                  window.location.pathname === "/profile"
                    ? "fas fa-fw fa-user ActiveColor mr-3"
                    : "fas fa-fw fa-user mr-3"
                }`}
              ></i>
              <span>{lang === "en" ? "Profile" : "档案"}</span>
            </Link>

            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordionSidebar"
            >
              <div className="bg-white py-2 collapse-inner rounded">
                <h6 className="collapse-header">Custom Components:</h6>
                <a className="collapse-item" href="buttons.html">
                  Buttons
                </a>
                <a className="collapse-item" href="cards.html">
                  Cards
                </a>
              </div>
            </div>
          </li>

          <hr className="sidebar-divider d-none d-md-block" />

          <div className="sidenav-text-center d-none d-md-inline">
            <button
              id="sidebarToggleTop"
              className="btn btn-link rounded-circle mr-3"
              onClick={sideNavHandler}
            >
              <i className="fa fa-bars"></i>
            </button>
          </div>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
