import { CButton } from "@coreui/react-pro";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { capitalizeWords } from "../Components/allFunctions";
import { CircleLoader } from "react-spinners";
import PageNotFound from "../Components/PageNotFound";

const CMS_ENDPOINT =
  process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_CMS_ROUTE;

const Profile = () => {
  const navigate = useNavigate();
  const [profileData, setprofileData] = useState({
    userData: {},
    loading: false,
    error: "",
  });

  useEffect(() => {
    setprofileData((presVal) => ({ ...presVal, loading: true }));
    const AccountId = localStorage.getItem("id");
    const AccountEmail = localStorage.getItem("email");
    fetch(`${CMS_ENDPOINT}/vendor/${AccountId}?email=${AccountEmail}`)
      .then((res) => res.json())
      .then((res) => {
        if (res.status === true) {
          setprofileData((presVal) => ({
            ...presVal,
            loading: false,
            userData: res?.data ?? {},
          }));
        } else {
          setprofileData((presVal) => ({
            ...presVal,
            loading: false,
            error: res.message,
          }));
          console.log(res.message);
          // localStorage.removeItem("LoggedIn", "false");
          // navigate("/");
        }
      })
      .catch((error) => {
        setprofileData((presVal) => ({
          ...presVal,
          loading: false,
          error: error.message,
        }));
        console.log(error.message);
        // localStorage.removeItem("LoggedIn", "false");
        // navigate("/");
      });
  }, []);

  return (
    <>
    {profileData.loading?(
      <div style={{ height: "100%" }} role="status">
      <CircleLoader className="LoaderSingleOrder" color="#1ca1ee" />
      </div>
    ): profileData.error === "" && profileData.userData ? (
      <div className="profilePage">
        <div className="coverDIv"></div>

        <div className="profileDets">
          <div className="profileImg">
            <img
              className="imgAvtar"
              src="https://bootdey.com/img/Content/avatar/avatar7.png"
              alt=""
            />
          </div>
          <div className="profileInfo">
            <h2 className="NameHead">{capitalizeWords(profileData?.userData?.name)}</h2>
            <h3>{profileData?.userData?.email}</h3>
            <h3>(239) 816-9029</h3>
            <h3>{capitalizeWords(profileData.userData?.tag ?? "")}</h3>
          </div>
        </div>
      </div>
      ) : (
       <PageNotFound />
        )}
      
        
    </>
  );
};
export default Profile;
