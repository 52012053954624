import {
  CButton,
  CForm,
  CFormCheck,
  CFormInput,
  CLoadingButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
  CSmartTable,
} from "@coreui/react-pro";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { capitalizeWords } from "../Components/allFunctions";
import { useNavigate } from "react-router-dom";

const CMS_ENDPOINT =
  process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_CMS_ROUTE;

  
  const Vendor = (props) => {
    const lang = props.Language;
    const navigate = useNavigate();
    const [loader, setLoader] = useState(true);
  const [State, setState] = useState({
    usersData: [],
    checkBoxCheck: {},
    TdCheckbox: false,
    passwordVisible: false,
    cPasswordVisible: false,
    visible: false,
    loading: { loader },
    Modeltitel: "",
    ModelButton: "",
    DltVal: {},
    DltVisible: false,
    DltLoading: false,
  });
  const [formVal, setFromVal] = useState({
    name: "",
    email: "",
    tag: "",
    password: "",
    cPassword: "",
    defaultTag: false,
  });
  const customTableFilterLabel = lang === "en" ? "Filter" : "筛选";
  
  const customItemsPerPageLabel =
    lang === "en" ? "Items per Page:" : "项目/页 : ";

  const handleCloseModal = () => {
    setState({
      ...State,
      passwordVisible: false,
      cPasswordVisible: false,
      visible: false,
      loading: false,
      DltVal: {},
      DltVisible: false,
      DltLoading: false,
    });
    setFromVal({
      name: "",
      email: "",
      tag: "",
      password: "",
      cPassword: "",
      defaultTag: false,
    });
  };

  const columns = [
    {
      key: "id",
      label: "ID",
      filter: false,
      sorter: false,
      _style: { width: "5%" },
    },
    {
      key: "name",
      label: lang === "en" ? "Name" : "名字",
      _style: { width: "15%" },
    },
    {
      key: "email",
      label: lang === "en" ? "Email" : "電子郵件",
      _style: { width: "25%" },
    },
    {
      key: "tag",
      label: lang === "en" ? "Vendor" : "供应商",
      _style: { width: "15%" },
    },
    {
      key: "operations",
      label: lang === "en" ? "Operations" : "作业/操作",
      filter: false,
      sorter: false,
      _style: { width: "20%" },
    },
    {
      key: "Default",
      label: lang === "en" ? "Default" : "默认",
      filter: false,
      sorter: false,
      _style: { width: "5%" },
    },
  ];

  const handleChangeInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setFromVal((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (Object.values(formVal).every((value) => value !== "")) {
      setState((prevState) => ({ ...prevState, loading: true }));
      if (formVal.password !== formVal.cPassword) {
        toast.error(lang === "en" ? "Password does not match" : "密码不匹配");
        setState((prevState) => ({ ...prevState, loading: false }));
      } else {
        if (State.ModelButton === lang === "en" ? "Submit" : "提交") {
          const { cPassword, ...updateState } = formVal;
          fetch(`${CMS_ENDPOINT}/vendor`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updateState),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.status === true) {
                toast.success(lang === "en" ? data.message : "账户添加成功");
                setState((prevState) => ({
                  ...prevState,
                  passwordVisible: false,
                  cPasswordVisible: false,
                  visible: false,
                  loading: false,
                  usersData: [
                    ...prevState.usersData,
                    { ...formVal, id: data.data },
                  ],
                }));

                if (formVal.defaultTag === true) {
                  const defaultaTagIndex = State.usersData.findIndex(
                    (e) => e.defaultTag === true
                  );
                  if (defaultaTagIndex >= 0) {
                    State.usersData[defaultaTagIndex].defaultTag = false;
                  }
                }

                setFromVal({
                  name: "",
                  email: "",
                  tag: "",
                  password: "",
                  cPassword: "",
                  defaultTag: false,
                });
              } else {
                toast.error(lang === "en" ? "Email already exist":"电子邮件已存在");
                // Do NOT close the modal
                setState((prevState) => ({
                  ...prevState,
                  visible: true,
                  loading: false,
                }));
              }
            })
            .catch((error) => {
              setState((prevState) => ({
                ...prevState,
                visible: true,
                loading: false,
              }));
              toast.error(error.message);
              console.log("line 190" + error.message)
            });
        }
        if (State.ModelButton === "Update") {
          const { id, cPassword, email, ...updateState } = formVal;
          fetch(`${CMS_ENDPOINT}/vendor/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(updateState),
          })
            .then((response) => response.json())
            .then((data) => {
              if (data.status === true) {
                toast.success(lang === "en"? data.message: "账户更新成功");

                const findIndex = State.usersData?.findIndex(
                  (e) => e.email === formVal.email
                );
                if (findIndex !== -1) {
                  // setState(prevState => ({
                  //   ...prevState,
                  //   visible: false,
                  //   loading: false,
                  //   usersData: [ ...prevState.usersData.slice(0, findIndex), { ...prevState.usersData[ findIndex ], ...formVal }, ...prevState.usersData.slice(findIndex + 1) ]
                  // }));

                  setState((prevState) => {
                    const updatedUsersData = [...prevState.usersData];
                    updatedUsersData[findIndex] = {
                      ...prevState.usersData[findIndex],
                      ...formVal,
                    };

                    return {
                      ...prevState,
                      passwordVisible: false,
                      cPasswordVisible: false,
                      visible: false,
                      loading: false,
                      usersData: updatedUsersData,
                    };
                  });
                }

                if (formVal.defaultTag === true || formVal.defaultTag === 1) {
                  const defaultaTagIndex = State.usersData.findIndex(
                    (e) => e.defaultTag === true || formVal.defaultTag === 1
                  );
                  if (defaultaTagIndex >= 0) {
                    State.usersData[defaultaTagIndex].defaultTag = false;
                  }
                  State.usersData[findIndex].defaultTag = true;
                }
                setFromVal({
                  name: "",
                  email: "",
                  tag: "",
                  password: "",
                  cPassword: "",
                  defaultTag: false,
                });
              } else {
                toast.error(data.message);
                console.log("line256" + data.message)
                setState((prevState) => ({
                  ...prevState,
                  visible: true,
                  loading: false,
                }));
              }
            })
            .catch((error) => {
              setState((prevState) => ({
                ...prevState,
                visible: true,
                loading: false,
              }));
              toast.error(error.message);
              console.log("line 271" + error.message)
            });
        }
      }
    } else {
      toast.error(lang === "en" ? "Please fill in all fields" : "请填写所有字段");
    }
  };

  useEffect(() => {
    setLoader(true);
    fetch(`${CMS_ENDPOINT}/vendors`)
      .then((res) => res.json())
      .then((res) => {
        setState((prevState) => ({ ...prevState, usersData: res.data }));
        setLoader(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const handleChangeEdit = (value) => {
    setFromVal({ ...value, cPassword: value.password, id: value.id });
    setState((prevState) => ({
      ...prevState,
      Modeltitel: lang === "en" ? "Update vendor:" : "更新供應商",
      ModelButton: lang === "en" ? "Update" : "更新",
      visible: true,
    }));
  };

  const handlechangeDelete = () => {
    if (State.DltVal) {
      setState({ ...State, DltLoading: true });
      const { id, email } = State?.DltVal;
      fetch(`${CMS_ENDPOINT}/vendor/${id}`, {
        method: "DELETE",
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === true) {
            const FilterDelete = State.usersData.filter(
              (e) => e.email !== email
            );
            console.log(FilterDelete);
            setState((prevState) => ({
              ...prevState,
              usersData: FilterDelete,
              DltVisible: false,
              DltLoading: false,
            }));
            toast.success(lang === "en" ? res.message : "帐号删除成功");

          } else {
            toast.error(lang === "en" ? res.message : "帐号未成功删除");
            setState({ ...State, DltLoading: false });
          }
        })
        .catch((error) => {
          toast.error(error.message);
          console.log("line 334" + error.message)
          setState({ ...State, DltLoading: false });
        });
    }
  };

  const handleChangeCheck = (val) => {
    setFromVal((presVal) => ({ ...presVal, defaultTag: val.target.checked }));
  };

  const handleChangeCheckTbl = async (e, item) => {
    setState({ ...State, TdCheckbox: true });
    await fetch(`${CMS_ENDPOINT}/vendor/${item.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ defaultTag: e.target.checked }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          const findIndex = State.usersData?.findIndex(
            (e) => e.email === item.email
          );
          setTimeout(() => {
            toast.success(lang === "en" ? "Account Updated Successfully" : "账户更新成功");
            if (findIndex !== -1) {
              setState((prevState) => {
                const updatedUsersData = prevState.usersData.map(
                  (user, index) => ({
                    ...user,
                    defaultTag: index === findIndex ? !user.defaultTag : false,
                  })
                );
                return {
                  ...prevState,
                  TdCheckbox: false,
                  usersData: updatedUsersData,
                };
              });
            }
            console.log(State.usersData);
          }, 1000);
        } else {
          setState((prevState) => ({ ...prevState, TdCheckbox: false }));
          toast.error(data.message);
          console.log("line 382" + data.message)
        }
      })
      .catch((error) => {
        setState((prevState) => ({ ...prevState, TdCheckbox: false }));
        toast.error(error.message);
        console.log("line 388" + error.message)
      });
  };

  return (
    <>
      <div className="container-fluid">
        {/* <div className="ForAndBak">
      <div className="BackButton" onClick={() => navigate(-1)}><i className="fas fa-arrow-left backbtn"></i></div>
        </div> */}

        <div>
          <h1 className="h3 mb-2 text-gray-800 HeadTitles">
            {lang === "en" ? "Vendor" : "供应商"}
          </h1>
        </div>
        <div>
          <CButton
            color="warning"
            onClick={() =>
              setState({
                ...State,
                visible: !State.visible,
                Modeltitel: lang === "en" ? "Create vendor" : "創建供應商",
                ModelButton: lang === "en" ? "Submit" : "提交",
              })
            }
          >
            + {lang === "en" ? "Add Vendor" : "添加供货商"}
          </CButton>
        </div>
        <br />

        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="mb-12 VendorTable">
              <CSmartTable
                activePage={1}
                responsive={"true"}
                tableFilter
                tableFilterPlaceholder={lang === "en" ? "Search..." : "搜索..."}
                tableFilterLabel={customTableFilterLabel}
                columns={columns}
                loading={loader}
                columnSorter
                items={State.usersData}
                itemsPerPageSelect
                itemsPerPage={10}
                itemsPerPageLabel={customItemsPerPageLabel}
                pagination
                // onFilteredItemsChange={(items) => {
                //   console.log("filter",items)
                // }}
                // onSelectedItemsChange={(items) => {
                //   console.log("select", items)
                // }}
                tableProps={{
                  hover: true,
                  responsive: true,
                  striped: true,
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
                scopedColumns={{
                  id: (item, i) => <td>{i + 1}.</td>,
                  name: (item, i) => (
                    <td>{capitalizeWords(item?.name ?? "")}</td>
                  ),
                  tag: (item, i) => <td>{capitalizeWords(item?.tag ?? "")}</td>,
                  operations: (item, i) => (
                    // item.defaultTag ? (
                    //   <td>Default</td>
                    // ) : (
                    <td>
                      <CButton
                        color="primary"
                        onClick={() => handleChangeEdit(item)}
                      >
                        {lang === "en" ? "Edit" : "編輯"}
                      </CButton>{" "}
                      <CButton
                        color="danger"
                        onClick={() =>
                          setState((presVal) => ({
                            ...presVal,
                            DltVisible: true,
                            DltVal: { id: item.id, email: item.email },
                          }))
                        }
                      >
                        {lang === "en" ? "Delete" : "刪除"}
                      </CButton>
                    </td>
                  ),
                  Default: (item, i) => {
                    return (
                      <td>
                        <CFormCheck
                          id="defaultTag1"
                          onChange={(e) => handleChangeCheckTbl(e, item)}
                          checked={
                            item.defaultTag === true || item.defaultTag === 1
                              ? true
                              : false
                          }
                          disabled={State.TdCheckbox}
                        />
                      </td>
                    );
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <CModal
        alignment="center"
        visible={State.visible}
        onClosePrevented={handleCloseModal}
      >
        <CModalHeader>
          <CModalTitle>{State.Modeltitel}</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <CForm>
            <div className="mb-3">
              <CFormInput
                type="text"
                label={lang === "en" ? "Name*" : "名字"}
                placeholder={lang === "en" ? "Name*" : "名字"}
                name="name"
                value={formVal.name}
                onChange={handleChangeInput}
              />
            </div>
            <div className="mb-3">
              {State.ModelButton === "Update" ? (
                ""
              ) : (
                <CFormInput
                  type="email"
                  label={lang === "en" ? "Email address*" : "電子郵件地址"}
                  placeholder="name@example.com"
                  name="email"
                  value={formVal.email}
                  onChange={handleChangeInput}
                />
              )}
            </div>
            <div className="mb-3">
              <CFormInput
                type="text"
                label={lang === "en" ? "Vendor" : "供应商"}
                placeholder={lang === "en" ? "Vendor" : "供应商"}
                name="tag"
                value={formVal.tag}
                onChange={handleChangeInput}
              />
            </div>
            <div className="position-relative mb-3">
              <CFormInput
                type={State.passwordVisible ? "text" : "password"}
                label={lang === "en" ? "Password*" : "密码"}
                placeholder={lang === "en" ? "Password*" : "密码"}
                name="password"
                value={formVal.password}
                onChange={handleChangeInput}
              />
              <i
                className={`fa ${
                  State.passwordVisible ? "fa-eye-slash" : "fa-eye"
                } showpassword-eye`}
                aria-hidden="true"
                onClick={() =>
                  setState({
                    ...State,
                    passwordVisible: !State.passwordVisible,
                  })
                }
              ></i>
            </div>
            <div className="position-relative mb-3">
              <CFormInput
                type={State.cPasswordVisible ? "text" : "password"}
                label={lang === "en" ? "Confirm Password*" : "确认密码"}
                placeholder={lang === "en" ? "Confirm Password*" : "确认密码"}
                name="cPassword"
                value={formVal.cPassword}
                onChange={handleChangeInput}
              />
              <i
                className={`fa ${
                  State.cPasswordVisible ? "fa-eye-slash" : "fa-eye"
                } showpassword-eye`}
                aria-hidden="true"
                onClick={() =>
                  setState({
                    ...State,
                    cPasswordVisible: !State.cPasswordVisible,
                  })
                }
              ></i>
            </div>
            <div className="mb-3">
              <CFormCheck
                id="defaultTag1"
                label={lang === "en" ? "Mark as default" : "选择默认"}
                onChange={handleChangeCheck}
                checked={formVal.defaultTag}
              />
            </div>
          </CForm>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={handleCloseModal}>
            {lang === "en" ? "Close" : "关闭"}
          </CButton>
          {State.loading ? (
            <CLoadingButton color="primary" disabled></CLoadingButton>
          ) : (
            <CButton color="primary" onClick={handleSubmit}>
              {State.ModelButton}
            </CButton>
          )}
        </CModalFooter>
      </CModal>

      <CModal visible={State.DltVisible} onClosePrevented={handleCloseModal}>
        <CModalHeader>
          <CModalTitle>
            {lang === "en" ? "Delete Confirmation" : "删除确认"}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          {lang === "en"
            ? "Are you sure you wish to delete this vendor?"
            : "你确认要删除这个供货商吗 ?"}
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={handleCloseModal}>
            {lang === "en" ? "Close" : "关闭"}
          </CButton>
          {State.DltLoading ? (
            <CLoadingButton color="danger" disabled></CLoadingButton>
          ) : (
            <CButton color="danger" onClick={handlechangeDelete}>
              {lang === "en" ? "Confirm Delete" : "确认删除"}
            </CButton>
          )}
        </CModalFooter>
      </CModal>
      <Toaster />
    </>
  );
};
export default Vendor;
