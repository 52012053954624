import { CButton, CFormCheck, CSmartTable } from "@coreui/react-pro";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { capitalizeWords } from "../Components/allFunctions";

const CMS_ENDPOINT =
  process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_CMS_ROUTE;

const API_ENDPOINT = process.env.REACT_APP_API_BASE_DEV_URL + "/api/enterprise";

const Vendor = (props) => {
  const lang = props.Language;
  const [loader, setLoader] = useState(true);
  const [State, setState] = useState({
    usersData: [],
    checkBoxCheck: {},
    TdCheckbox: false,
    passwordVisible: false,
    cPasswordVisible: false,
    visible: false,
    loading: { loader },
    Modeltitel: "",
    ModelButton: "",
    DltVal: {},
    DltVisible: false,
    DltLoading: false,
  });
  const [formVal, setFromVal] = useState({
    name: "",
    email: "",
    tag: "",
    password: "",
    cPassword: "",
    defaultTag: false,
  });
  const customTableFilterLabel = lang === "en" ? "Filter" : "筛选";

  const customItemsPerPageLabel =
    lang === "en" ? "Items per Page:" : "项目/页 : ";

  const columns = [
    {
      key: "id",
      label: "ID",
      filter: false,
      sorter: false,
      _style: { width: "5%" },
    },
    {
      key: "name",
      label: lang === "en" ? "Enterprise Name" : "名字",
      filter: false,
      sorter: false,
      _style: { width: "25%" },
    },
    {
      key: "email",
      label: lang === "en" ? "Email" : "電子郵件",
      filter: false,
      sorter: false,
      _style: { width: "25%" },
    },
    {
      key: "accountholder",
      label: lang === "en" ? "Primary Account Holder" : "供应商",
      filter: false,
      sorter: false,
      _style: { width: "25%" },
    },
    {
      key: "phone",
      label: lang === "en" ? "Phone" : "作业/操作",
      filter: false,
      sorter: false,
      _style: { width: "20%" },
    },
  ];

  useEffect(() => {
    setLoader(true);
    fetch(API_ENDPOINT)
      .then((res) => res.json())
      .then((res) => {
        setState((prevState) => ({ ...prevState, usersData: res.data }));
        setLoader(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  const handleChangeEdit = (value) => {
    setFromVal({ ...value, cPassword: value.password, id: value.id });
    setState((prevState) => ({
      ...prevState,
      Modeltitel: lang === "en" ? "Update vendor:" : "更新供應商",
      ModelButton: lang === "en" ? "Update" : "更新",
      visible: true,
    }));
  };

  return (
    <>
      <div className="container-fluid">
        <div>
          <h1 className="h3 mb-2 text-gray-800 HeadTitles">
            {lang === "en" ? "Enterprise" : "供应商"}
          </h1>
        </div>

        <br />

        <div className="card shadow mb-4">
          <div className="card-body">
            <div className="mb-12 VendorTable">
              <CSmartTable
                activePage={1}
                responsive={"true"}
                tableFilterPlaceholder={lang === "en" ? "Search..." : "搜索..."}
                tableFilterLabel={customTableFilterLabel}
                columns={columns}
                loading={loader}
                columnSorter
                items={State.usersData}
                itemsPerPageSelect
                itemsPerPage={10}
                itemsPerPageLabel={customItemsPerPageLabel}
                pagination
                tableProps={{
                  hover: true,
                  responsive: true,
                  striped: true,
                }}
                tableBodyProps={{
                  className: "align-middle",
                }}
                scopedColumns={{
                  id: (item, i) => <td>{i + 1}.</td>,
                  name: (item, i) => (
                    <td>{capitalizeWords(item?.EnterpriseName ?? "")}</td>
                  ),
                  email: (item, i) => <td>{item?.Email ?? ""}</td>,
                  accountholder: (item, i) => (
                    <td>{capitalizeWords(item?.Email ?? "")}</td>
                  ),
                  phone: (item, i) => <td>{item?.Phone ?? ""}</td>,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Toaster />
    </>
  );
};
export default Vendor;
