import { CSmartTable } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { capitalizeWords } from "../../Components/allFunctions";

const CMS_ENDPOINT =
  process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_CMS_ROUTE;

const OpenOrder = () => {
  const [unFullfillesData, setUnFullfilledData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [accessToken, setAccessToken] = useState("");

  const columns = [
    {
      key: "id",
      label: "ID",
      filter: false,
      sorter: false,
    },
    {
      key: "OrderName",
      label: "Order",
    },
    {
      key: "CreatedAt",
      label: "Date",
    },
    {
      key: "CustomerName",
      label: "Customer",
    },
    {
      key: "FulfillmentStatus",
      label: "Fulfillment Status",
    },
    {
      key: "Items",
      label: "Items",
    },
    {
      key: "Tag",
      label: "Tags",
    },
    {
      key: "Flag",
      label: "Flagged",
    },
    {
      key: "FlagBy",
      label: "Flagged By",
    },
  ];

  const getUnFullfilledOrders = () => {
    try {
      setLoading(true);
      let email = localStorage.getItem("email");
      let id = localStorage.getItem("id");

      if (email !== null && id !== null) {
        fetch(`${CMS_ENDPOINT}/open-orders?email=${email}&id=${id}`)
          .then((response) => response.json())
          .then((unFullfillesData) => {
            setUnFullfilledData(unFullfillesData.orders);
            setLoading(false);
          });
      } else {
        setLoading(false);
        console.log("Please Login First");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUnFullfilledOrders();
  }, []);

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800">Orders</h1>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 mb-12">
          <h3>Open</h3>
          <div className="card shadow mb-4 flaged">
            <div className="card-body orders-table">
              <CSmartTable
                responsive="true"
                items={unFullfillesData}
                columns={columns}
                columnSorter
                tableFilter
                tableFilterPlaceholder="Search..."
                pagination
                loading={loading}
                itemsPerPage={20}
                itemsPerPageSelect
                tableProps={{
                  hover: true,
                  responsive: true,
                  striped: true,
                }}
                scopedColumns={{
                  id: (item, i) => <td>{i + 1}.</td>,
                  OrderName: (items) => {
                    return (
                      <td>
                        <Link to={`/orders/${items.id}`} className="flagedLink">
                          {items.OrderName}
                        </Link>
                      </td>
                    );
                  },
                  Flag: (items) => {
                    return <td>{items.Flag === 1 ? "Flag" : ""}</td>;
                  },
                  FlagBy: (items) => {
                    return (
                      <td>
                        {items.FlagBy == "null" || items.Flag === 0
                          ? ""
                          : items.FlagBy}
                      </td>
                    );
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenOrder;
